.page-course-lesson-create {
  padding: 25px 25px 75px 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-course-lesson-create .page-header {
    display: flex;
    align-items: center; }
    .page-course-lesson-create .page-header .back-icon {
      font-size: 24px;
      margin-right: 10px; }
    .page-course-lesson-create .page-header h1 {
      margin-bottom: 0; }
  .page-course-lesson-create .flex-l {
    display: flex;
    flex-direction: column; }
  .page-course-lesson-create h3 {
    color: #8f9bb3; }
  .page-course-lesson-create .input {
    height: 50px;
    font-size: 18px;
    color: #404040; }
  .page-course-lesson-create .textarea {
    font-size: 14px;
    padding: 10px;
    min-height: 120px; }
  .page-course-lesson-create .editor {
    background: white;
    padding: 15px;
    height: 800px; }
  .page-course-lesson-create .lesson-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: white;
    border-radius: 4px;
    width: 100%;
    padding: 15px; }
    .page-course-lesson-create .lesson-list .lesson-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      border-bottom: 1px solid #eeeeee; }
      .page-course-lesson-create .lesson-list .lesson-item .title {
        font-size: 15px;
        color: #333333;
        flex: 1; }
      .page-course-lesson-create .lesson-list .lesson-item .flex-r {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; }
        .page-course-lesson-create .lesson-list .lesson-item .flex-r .item-props {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start; }
          .page-course-lesson-create .lesson-list .lesson-item .flex-r .item-props .duration {
            font-size: 14px;
            color: #8f9bb3;
            margin-right: 15px; }
          .page-course-lesson-create .lesson-list .lesson-item .flex-r .item-props .status {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100px; }
        .page-course-lesson-create .lesson-list .lesson-item .flex-r .item-actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          margin-left: 20px; }
          .page-course-lesson-create .lesson-list .lesson-item .flex-r .item-actions a {
            margin: 5px; }
    .page-course-lesson-create .lesson-list .flex-b {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%; }
      .page-course-lesson-create .lesson-list .flex-b .add-lesson {
        background: none;
        border: 1px dashed #3340ff;
        margin-top: 20px;
        font-size: 15px;
        color: #3340ff;
        height: 40px;
        width: 80%;
        text-align: center;
        border-radius: 4px;
        line-height: 40px;
        cursor: pointer; }
  .page-course-lesson-create .bottom-bar {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    border-top: 1px solid #eeeeee; }
    .page-course-lesson-create .bottom-bar .publish-switch {
      margin-right: 20px; }

.modal-course-owners .item {
  height: 130px; }

.course-form-item {
  display: flex;
  flex-direction: column;
  padding: 10px; }
  .course-form-item h3 {
    color: #8f9bb3; }
  .course-form-item .upload-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    padding: 15px;
    background: white;
    border-radius: 4px; }
    .course-form-item .upload-item .progress {
      flex: 1; }
    .course-form-item .upload-item .cancel-upload {
      margin-left: 15px; }
  .course-form-item .uploader .ant-upload-btn {
    padding-bottom: 0; }
  .course-form-item .uploader .uploader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .course-form-item .category-selector {
    height: 50px;
    font-size: 18px; }
    .course-form-item .category-selector input {
      height: 100%; }
  .course-form-item .price-item {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .course-form-item .price-item .price-input {
      font-size: 20px;
      height: 50px;
      font-weight: 600;
      color: #ff5113;
      width: 300px;
      margin-left: 10px; }
      .course-form-item .price-item .price-input .ant-input-number-input-wrap {
        height: 100%; }
      .course-form-item .price-item .price-input input {
        height: 100%; }
  .course-form-item .input {
    height: 50px;
    font-size: 18px;
    color: #404040; }
  .course-form-item .textarea {
    font-size: 14px;
    padding: 10px;
    min-height: 120px; }

.modal-edit-source .course-form-item {
  position: relative; }

.modal-edit-source .progress-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9; }

.modal-edit-source .uploader-container {
  min-height: 300px; }

.modal-edit-source .form-btn {
  margin-top: 20px; }

.modal-edit-source .source-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; }
  .modal-edit-source .source-file .zip-icon {
    font-size: 60px; }
  .modal-edit-source .source-file .bottom {
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .modal-edit-source .source-file .bottom .time {
      margin-left: 20px; }
