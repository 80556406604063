.bks-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  background: #ffffff;
  width: 100%;
  flex: auto;
  border-bottom: 1px solid #fdfdfd; }
  .bks-nav .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0 25px; }
    .bks-nav .container .logo {
      height: 25px; }
    .bks-nav .container .flex-r {
      display: flex;
      align-items: center; }
      .bks-nav .container .flex-r .post-img {
        width: 30px;
        height: 30px;
        margin-right: 23px;
        cursor: pointer; }
      .bks-nav .container .flex-r .point {
        width: 2px;
        height: 2px;
        border-radius: 1px;
        background: #333333;
        margin: 0 7px; }
      .bks-nav .container .flex-r a {
        font-family: PingFangSC-Regular, sans-serif;
        font-size: 14px;
        color: #59B6FF;
        letter-spacing: 0; }

.modal-edit-account h3 {
  margin-bottom: 30px; }

.modal-edit-account .social-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0; }
  .modal-edit-account .social-item .social-title {
    width: 100px;
    text-align: end;
    margin-right: 15px; }

.modal-edit-account .flex-b {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px; }
