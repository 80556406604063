.page-qm-wx-project {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-qm-wx-project .page-header {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .page-qm-wx-project .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .page-qm-wx-project .header .flex-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
      .page-qm-wx-project .header .flex-l h2 {
        margin-bottom: 0; }
      .page-qm-wx-project .header .flex-l .presort-group {
        margin-left: 20px; }
    .page-qm-wx-project .header .flex-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      .page-qm-wx-project .header .flex-r .search-input {
        margin-right: 20px;
        width: 250px; }
  .page-qm-wx-project .list {
    margin-top: 50px; }

.modal-edit-shot-category .textarea {
  margin-top: 20px; }
