.comp-user-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%; }
  .comp-user-list-item .recent-login {
    font-size: 13px;
    padding: 0;
    margin: 0; }
  .comp-user-list-item .flex-l {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    padding-right: 15px; }
    .comp-user-list-item .flex-l .avatar {
      width: 50px;
      height: 50px; }
    .comp-user-list-item .flex-l .nickname {
      font-size: 16px;
      font-weight: 600;
      flex: 1; }
  .comp-user-list-item .flex-m {
    flex: 1; }
  .comp-user-list-item .flex-r {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .comp-user-list-item .ban-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center; }
    .comp-user-list-item .ban-list .ban-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 10px 10px 10px 0; }
      .comp-user-list-item .ban-list .ban-item .ban-icon {
        font-size: 12px;
        color: red; }
      .comp-user-list-item .ban-list .ban-item div {
        font-size: 13px;
        margin-left: 2px; }
