.page-shot-dash {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-shot-dash .page-header {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .page-shot-dash .statistic-section {
    padding: 15px;
    margin-bottom: 40px; }
    .page-shot-dash .statistic-section .col-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 165px;
      background: white;
      border-radius: 4px;
      padding: 15px; }
      .page-shot-dash .statistic-section .col-card .col-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        margin-bottom: 10px;
        cursor: pointer; }
        .page-shot-dash .statistic-section .col-card .col-group:hover {
          color: #3340ff; }
      .page-shot-dash .statistic-section .col-card .col-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 14px;
        margin-bottom: 8px;
        cursor: pointer; }
        .page-shot-dash .statistic-section .col-card .col-child:hover {
          color: #3340ff; }
      .page-shot-dash .statistic-section .col-card .group-selected {
        color: #3340ff; }
  .page-shot-dash .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .page-shot-dash .header .flex-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
      .page-shot-dash .header .flex-l h2 {
        margin-bottom: 0; }
      .page-shot-dash .header .flex-l .presort-group {
        margin-left: 20px; }
    .page-shot-dash .header .flex-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      .page-shot-dash .header .flex-r .search-input {
        margin-right: 20px;
        width: 250px; }
  .page-shot-dash .course-list {
    margin-top: 20px; }

.modal-edit-shot-modal {
  width: 80%; }
  .modal-edit-shot-modal .rich-text {
    width: 100%; }
    .modal-edit-shot-modal .rich-text img {
      width: 100%; }
