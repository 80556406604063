.page-debug-setting {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-debug-setting .statistic-section {
    padding: 15px; }
  .page-debug-setting .list-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .page-debug-setting .list-header h2 {
      margin-bottom: 0; }
    .page-debug-setting .list-header .flex-r {
      margin-right: 20px; }
      .page-debug-setting .list-header .flex-r button {
        margin-right: 20px; }
  .page-debug-setting .list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%; }
    .page-debug-setting .list .item {
      background: white;
      padding: 20px;
      margin: 10px 0; }
      .page-debug-setting .list .item h3 {
        color: #8f9bb3; }
