.comp-modal-user-searcher {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 50px; }
  .comp-modal-user-searcher .searcher-container {
    margin-top: 50px;
    height: 400px;
    width: 100%;
    flex: 1; }
    .comp-modal-user-searcher .searcher-container .searcher-status {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 100%; }
    .comp-modal-user-searcher .searcher-container .result-count {
      font-size: 14px;
      color: #8F9BB3;
      text-align: center; }
    .comp-modal-user-searcher .searcher-container .search-result {
      margin-top: 15px;
      width: 100%;
      overflow-y: scroll;
      flex: 1;
      margin-bottom: 20px; }
      .comp-modal-user-searcher .searcher-container .search-result .searched-user {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10px; }
        .comp-modal-user-searcher .searcher-container .search-result .searched-user .searched-user-inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative; }
          .comp-modal-user-searcher .searcher-container .search-result .searched-user .searched-user-inner .teacher-icon {
            position: absolute;
            top: -10px;
            right: -30px;
            z-index: 2;
            font-size: 30px;
            color: #fcba28; }
          .comp-modal-user-searcher .searcher-container .search-result .searched-user .searched-user-inner .avatar {
            width: 50px;
            height: 50px; }
          .comp-modal-user-searcher .searcher-container .search-result .searched-user .searched-user-inner .nickname {
            font-size: 14px;
            margin-top: 7px; }
          .comp-modal-user-searcher .searcher-container .search-result .searched-user .searched-user-inner button {
            font-size: 12px;
            line-height: 1;
            height: 18px;
            padding: 2px 4px;
            margin-top: 5px; }
