.page-qm-wx-menu {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-qm-wx-menu .page-header {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .page-qm-wx-menu .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .page-qm-wx-menu .header .flex-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
      .page-qm-wx-menu .header .flex-l h2 {
        margin-bottom: 0; }
      .page-qm-wx-menu .header .flex-l .presort-group {
        margin-left: 20px; }
    .page-qm-wx-menu .header .flex-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      .page-qm-wx-menu .header .flex-r .search-input {
        margin-right: 20px;
        width: 250px; }
  .page-qm-wx-menu .card {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: white;
    border-radius: 5px;
    align-items: stretch;
    margin: 20px 0; }
    .page-qm-wx-menu .card .textarea {
      min-height: 300px; }
    .page-qm-wx-menu .card .item {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 20px 0; }
      .page-qm-wx-menu .card .item .item-title {
        margin-bottom: 10px; }
    .page-qm-wx-menu .card .footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 30px; }
