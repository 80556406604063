.page-so-uigreat-index {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-so-uigreat-index .cover-btn {
    color: #1490ff;
    border: none;
    box-shadow: none;
    padding: 0;
    margin-right: 20px; }
    .page-so-uigreat-index .cover-btn button:disabled {
      background-color: white;
      border: none; }
  .page-so-uigreat-index .statistic-section {
    padding: 15px; }
  .page-so-uigreat-index .list-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .page-so-uigreat-index .list-header h2 {
      margin-bottom: 0; }
    .page-so-uigreat-index .list-header .flex-r {
      margin-right: 20px; }
      .page-so-uigreat-index .list-header .flex-r button {
        margin-right: 20px; }
  .page-so-uigreat-index .list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%; }
    .page-so-uigreat-index .list .group-loading {
      margin-top: 50px; }
    .page-so-uigreat-index .list .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      .page-so-uigreat-index .list .actions a {
        margin-right: 20px; }
    .page-so-uigreat-index .list .group-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px; }
      .page-so-uigreat-index .list .group-item .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; }
        .page-so-uigreat-index .list .group-item .header h3 {
          margin-bottom: 0; }
        .page-so-uigreat-index .list .group-item .header .actions {
          margin-right: 20px; }
          .page-so-uigreat-index .list .group-item .header .actions a {
            color: #3340ff; }
      .page-so-uigreat-index .list .group-item h3 {
        height: 50px;
        line-height: 50px; }
      .page-so-uigreat-index .list .group-item .child-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background: white; }
        .page-so-uigreat-index .list .group-item .child-list .child-item {
          padding: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          border-bottom: 1px solid #eeeeee;
          height: 50px; }
          .page-so-uigreat-index .list .group-item .child-list .child-item .flex-l {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1; }
            .page-so-uigreat-index .list .group-item .child-list .child-item .flex-l a {
              display: flex;
              flex-direction: row;
              align-items: center; }
              .page-so-uigreat-index .list .group-item .child-list .child-item .flex-l a div {
                color: #333333;
                font-weight: bold; }
            .page-so-uigreat-index .list .group-item .child-list .child-item .flex-l img {
              margin-right: 10px;
              width: 34px;
              height: 34px;
              border-radius: 17px; }
          .page-so-uigreat-index .list .group-item .child-list .child-item .desc {
            font-size: 12px;
            color: #c6c6c6;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            margin-left: 20px;
            flex: 1; }
  .page-so-uigreat-index .soft-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px; }
    .page-so-uigreat-index .soft-list .col-flex {
      display: flex;
      justify-content: center;
      align-items: center; }
    .page-so-uigreat-index .soft-list .soft-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: white;
      border-radius: 4px;
      height: 150px;
      width: 150px;
      position: relative;
      margin-left: 20px;
      margin-bottom: 10px; }
      .page-so-uigreat-index .soft-list .soft-item .flex-t {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .page-so-uigreat-index .soft-list .soft-item .flex-t img {
          width: 50px; }
        .page-so-uigreat-index .soft-list .soft-item .flex-t .name {
          margin-top: 10px; }
      .page-so-uigreat-index .soft-list .soft-item .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 30px;
        width: 100%;
        padding-right: 15px; }
        .page-so-uigreat-index .soft-list .soft-item .actions .icon {
          font-size: 13px;
          margin-left: 15px;
          color: #c1c1c1;
          cursor: pointer; }
    .page-so-uigreat-index .soft-list .soft-add {
      border-radius: 4px;
      border: 1px dashed #3340ff;
      cursor: pointer;
      background: none;
      margin: 0;
      padding: 0;
      height: 70px;
      width: 70px; }
      .page-so-uigreat-index .soft-list .soft-add .icon {
        font-size: 16px;
        color: #3340ff; }

.modal-edit-shot-category .form-item {
  display: flex;
  flex-direction: column;
  padding: 10px; }
  .modal-edit-shot-category .form-item .app-icon-uploader .app-icon-add {
    height: 100px;
    width: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 10px;
    color: #3340ff;
    border: 1px dashed #3340ff;
    margin-right: 20px;
    cursor: pointer; }
  .modal-edit-shot-category .form-item .app-icon-uploader img {
    height: 100px;
    width: 100px;
    margin-right: 20px; }
  .modal-edit-shot-category .form-item .time-range {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px; }
    .modal-edit-shot-category .form-item .time-range .symbol {
      margin: 0 10px; }
  .modal-edit-shot-category .form-item .uploader {
    width: 320px; }
    .modal-edit-shot-category .form-item .uploader .ant-upload-btn {
      padding-bottom: 0; }
    .modal-edit-shot-category .form-item .uploader .uploader-container {
      width: 100%;
      min-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative; }
      .modal-edit-shot-category .form-item .uploader .uploader-container .progress {
        position: absolute; }
      .modal-edit-shot-category .form-item .uploader .uploader-container .tip {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%; }
      .modal-edit-shot-category .form-item .uploader .uploader-container .course-preview {
        width: 100%;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .modal-edit-shot-category .form-item .uploader .uploader-container .course-preview img {
          width: 100%;
          height: 100%; }
  .modal-edit-shot-category .form-item .category-selector {
    height: 50px;
    font-size: 18px; }
    .modal-edit-shot-category .form-item .category-selector input {
      height: 100%; }
  .modal-edit-shot-category .form-item .price-item {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .modal-edit-shot-category .form-item .price-item .price-input {
      font-size: 20px;
      height: 50px;
      font-weight: 600;
      color: #ff5113;
      width: 300px;
      margin-left: 10px; }
      .modal-edit-shot-category .form-item .price-item .price-input .ant-input-number-input-wrap {
        height: 100%; }
      .modal-edit-shot-category .form-item .price-item .price-input input {
        height: 100%; }
    .modal-edit-shot-category .form-item .price-item .ant-input-number-disabled {
      color: #cccccc; }
  .modal-edit-shot-category .form-item .time-range-picker {
    margin-bottom: 15px; }

.modal-edit-shot-category .modal-item {
  display: flex;
  align-items: center; }
  .modal-edit-shot-category .modal-item i {
    font-size: 40px;
    text-align: center;
    padding-right: 10px; }

.modal-edit-shot-category .flex-l {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .modal-edit-shot-category .flex-l .input {
    flex: 1; }

.modal-edit-shot-category .input {
  height: 40px;
  margin-top: 10px; }

.modal-edit-shot-category .footer {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .modal-edit-shot-category .footer .submit-btn {
    height: 40px;
    margin-top: 30px;
    width: 200px;
    align-self: center; }
