.bks-menus {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  position: relative;
  background: white;
  border-right: 1px solid #e8e8e8;
  z-index: 29;
  flex: none; }
  .bks-menus .inner-menus {
    padding-bottom: 50px;
    height: 100%;
    overflow-y: scroll; }
    .bks-menus .inner-menus .ant-menu-submenu-title {
      font-size: 15px;
      font-weight: bold;
      opacity: 0.8; }
  .bks-menus .icon {
    margin: 20px 0; }
  .bks-menus .collapse-btn {
    border-radius: 0;
    width: 100%;
    border: none;
    height: 50px;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background: white;
    z-index: 9; }
  .bks-menus .ant-menu-inline {
    border-right: none; }
  .bks-menus .menu-title {
    font-size: 14px; }
  .bks-menus .menu-icon {
    font-size: 14px; }
