.page-course-board {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-course-board .red-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px; }
  .page-course-board .page-header {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .page-course-board .statistic-section {
    padding: 15px;
    margin-bottom: 40px; }
    .page-course-board .statistic-section .col-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      min-height: 165px;
      background: white;
      border-radius: 4px;
      padding: 15px; }
      .page-course-board .statistic-section .col-card .col-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        margin-bottom: 10px;
        cursor: pointer; }
        .page-course-board .statistic-section .col-card .col-group:hover {
          color: #3340ff; }
      .page-course-board .statistic-section .col-card .col-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 14px;
        margin-bottom: 8px;
        cursor: pointer; }
        .page-course-board .statistic-section .col-card .col-child:hover {
          color: #3340ff; }
      .page-course-board .statistic-section .col-card .group-selected {
        color: #3340ff; }
  .page-course-board .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .page-course-board .header .flex-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
      .page-course-board .header .flex-l h2 {
        margin-bottom: 0; }
      .page-course-board .header .flex-l .presort-group {
        margin-left: 20px; }
    .page-course-board .header .flex-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      .page-course-board .header .flex-r .search-input {
        margin-right: 20px;
        width: 250px; }
  .page-course-board .course-list {
    margin-top: 20px; }
