.page-user-board {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .page-user-board .statistic-section {
    padding: 15px; }
  .page-user-board .list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .page-user-board .list-header .flex-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 300px; }

.modal-edit-user-modal .flex-t {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px; }
  .modal-edit-user-modal .flex-t .avatar {
    height: 50px;
    width: 50px; }
  .modal-edit-user-modal .flex-t .flex-t-r {
    display: flex;
    flex-direction: column;
    margin-left: 15px; }
    .modal-edit-user-modal .flex-t .flex-t-r .nickname {
      font-size: 16px;
      color: #333333;
      font-weight: 500; }
    .modal-edit-user-modal .flex-t .flex-t-r .username, .modal-edit-user-modal .flex-t .flex-t-r .email {
      font-size: 12px;
      margin-top: 4px; }

.modal-edit-user-modal h3 {
  margin-top: 20px;
  margin-bottom: 10px; }

.modal-edit-user-modal .flex-b {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 50px; }
  .modal-edit-user-modal .flex-b button {
    min-width: 160px; }
