.comp-comment-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  background: white; }
  .comp-comment-list-item .flex-l {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 120px; }
    .comp-comment-list-item .flex-l .nickname {
      font-size: 12px;
      text-align: center; }
  .comp-comment-list-item .flex-m {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    margin-left: 13px;
    margin-right: 20px; }
    .comp-comment-list-item .flex-m .content {
      padding: 8px;
      background: rgba(238, 238, 238, 0.19);
      border-radius: 4px;
      font-size: 14px;
      color: #797979;
      margin-bottom: 8px;
      flex: 1; }
    .comp-comment-list-item .flex-m .flex-m-b {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .comp-comment-list-item .flex-m .flex-m-b .flex-m-b-l {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        color: #c2c2c2; }
      .comp-comment-list-item .flex-m .flex-m-b .flex-m-b-r {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end; }
        .comp-comment-list-item .flex-m .flex-m-b .flex-m-b-r .statistic {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center; }
          .comp-comment-list-item .flex-m .flex-m-b .flex-m-b-r .statistic .stat-item {
            font-size: 12px; }
          .comp-comment-list-item .flex-m .flex-m-b .flex-m-b-r .statistic .icon {
            font-size: 14px;
            color: #6a6f7b;
            margin-right: 5px; }
        .comp-comment-list-item .flex-m .flex-m-b .flex-m-b-r .lesson-number {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-left: 20px; }
  .comp-comment-list-item .flex-r {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80px; }
    .comp-comment-list-item .flex-r .status {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .comp-comment-list-item .flex-r .status .label {
        font-size: 12px;
        margin-top: 4px;
        color: red; }
  .comp-comment-list-item .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 120px; }
    .comp-comment-list-item .actions a {
      padding: 5px; }
