@charset "UTF-8";
#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }

body {
  background-color: #F7F9FC !important; }

/* 富文本全局样式 */
.rich-text {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #222B45;
  line-height: 25px; }
  .rich-text .p {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #222B45;
    line-height: 25px; }

.api-tree {
  margin-top: 30px; }
  .api-tree li .ant-tree-node-content-wrapper {
    height: auto !important; }
    .api-tree li .ant-tree-node-content-wrapper:hover {
      background-color: white !important; }
  .api-tree li .ant-tree-node-selected {
    background-color: white !important; }
  .api-tree .tree-node-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    margin-bottom: 10px; }
    .api-tree .tree-node-container:hover .action {
      display: flex; }
    .api-tree .tree-node-container .group-title {
      color: black;
      font-size: 14px; }
    .api-tree .tree-node-container .flex-t {
      display: flex;
      flex-direction: column; }
      .api-tree .tree-node-container .flex-t .title {
        font-size: 12px;
        color: #343434; }
      .api-tree .tree-node-container .flex-t .path {
        font-size: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 12px; }
        .api-tree .tree-node-container .flex-t .path .method {
          font-weight: bolder;
          margin-right: 6px; }
        .api-tree .tree-node-container .flex-t .path .get {
          color: #3340ff;
          font-weight: bolder; }
        .api-tree .tree-node-container .flex-t .path .post {
          color: darkgreen;
          font-weight: bolder; }
        .api-tree .tree-node-container .flex-t .path .put {
          color: #cacc43; }
        .api-tree .tree-node-container .flex-t .path .delete {
          color: #cc5346; }
        .api-tree .tree-node-container .flex-t .path .all {
          color: rgba(0, 0, 0, 0.98); }
    .api-tree .tree-node-container .action {
      display: none;
      flex-direction: row;
      align-items: center;
      margin-left: 20px; }
      .api-tree .tree-node-container .action .action-btn {
        margin-left: 15px;
        font-size: 10px; }
