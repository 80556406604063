.page-project-home {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-project-home .page-header {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .page-project-home .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .page-project-home .header .flex-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
      .page-project-home .header .flex-l h2 {
        margin-bottom: 0; }
      .page-project-home .header .flex-l .presort-group {
        margin-left: 20px; }
    .page-project-home .header .flex-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      .page-project-home .header .flex-r .search-input {
        margin-right: 20px;
        width: 250px; }
  .page-project-home .card {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: white;
    border-radius: 5px;
    align-items: stretch;
    margin: 20px 0; }
    .page-project-home .card .articles .article {
      display: flex;
      border-bottom: 1px solid #eeeeee; }
      .page-project-home .card .articles .article .item {
        margin: 10px 0; }
      .page-project-home .card .articles .article .flex-r {
        display: flex;
        flex-direction: column;
        flex: 1; }
        .page-project-home .card .articles .article .flex-r .textarea {
          min-height: 70px; }
      .page-project-home .card .articles .article .flex-a {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 20px; }
    .page-project-home .card .item {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 20px 0; }
      .page-project-home .card .item .item-title {
        margin-bottom: 10px; }
      .page-project-home .card .item .textarea {
        min-height: 300px; }
      .page-project-home .card .item .app-icon-uploader .app-icon-add {
        height: 120px;
        width: 160px;
        line-height: 100px;
        text-align: center;
        border-radius: 4px;
        color: #3340ff;
        border: 1px dashed  #3340ff;
        margin-right: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center; }
      .page-project-home .card .item .app-icon-uploader img {
        height: 120px;
        width: 160px; }
    .page-project-home .card .footer {
      display: flex;
      justify-content: flex-end; }
