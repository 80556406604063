.bks-login-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 21px 47px;
  width: 100%; }
  .bks-login-register .header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #EDF1F7;
    height: 38px;
    position: relative; }
    .bks-login-register .header label {
      font-family: PingFang-SC-Regular, sans-serif;
      font-size: 14px;
      color: #59B6FF;
      margin-right: 20px; }
    .bks-login-register .header .guide-line {
      background: #59B6FF;
      height: 4px;
      border-radius: 2px;
      width: 44px;
      position: absolute;
      bottom: -2px;
      left: 0;
      transition: all 0.2s ease-out; }
  .bks-login-register .body {
    display: flex;
    flex-direction: column;
    align-items: stretch; }
  .bks-login-register .third-auth {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 15px; }
    .bks-login-register .third-auth .third-link-item {
      margin: 0 18px; }
      .bks-login-register .third-auth .third-link-item img {
        width: 40px;
        height: 40px;
        border-radius: 20px; }
  .bks-login-register .error-tip {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #FF5F4C;
    margin-top: 20px; }
    .bks-login-register .error-tip label {
      transition: all 0.3s ease-out;
      height: 35px; }

.form-body {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .form-body .form-input {
    height: 48px;
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 14px;
    color: #222B45;
    line-height: 24px; }
  .form-body .input-margin {
    margin-top: 30px; }
  .form-body .form-input::placeholder {
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 14px;
    color: #8F9BB3;
    line-height: 20px; }
  .form-body .form-button {
    height: 40px;
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    line-height: 16px;
    width: 100%;
    background: #59B6FF; }
  .form-body .form-item {
    margin-bottom: 0; }
  .form-body .forget-pwd {
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 12px;
    color: #8F9BB3;
    margin-top: 20px;
    align-self: flex-end; }
  .form-body .send-code-btn {
    background: #EDF1F7;
    border: none;
    font-family: PingFang-SC-Regular;
    font-size: 12px;
    color: #2E3A59;
    text-align: center;
    line-height: 16px; }
  .form-body .agrees-radio {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .form-body .agrees-radio span {
      font-family: PingFang-SC-Regular, sans-serif;
      font-size: 12px;
      color: #8F9BB3; }
      .form-body .agrees-radio span a {
        color: #222B45; }
  .form-body .go-login {
    margin-top: 18px;
    text-align: center;
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 12px;
    color: #8F9BB3; }
