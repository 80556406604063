.page-comment-board {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-comment-board .page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
  .page-comment-board .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .page-comment-board .header .flex-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
      .page-comment-board .header .flex-l h2 {
        margin-bottom: 0; }
      .page-comment-board .header .flex-l .presort-group {
        margin-left: 20px; }
    .page-comment-board .header .flex-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      .page-comment-board .header .flex-r .calendar-icon {
        font-size: 25px; }
  .page-comment-board .course-list {
    margin-top: 20px; }
