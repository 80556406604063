.comp-bulletin-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 15px; }
  .comp-bulletin-list-item .content {
    font-size: 16px;
    flex: 1;
    margin-bottom: 0 !important; }
  .comp-bulletin-list-item .duration {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 200px; }
  .comp-bulletin-list-item .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 200px; }
