.page-qieman-replay-setting .keyword-card .item .edit-item, .modal-create .edit-item {
  border: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }

.page-qieman-replay-setting {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-qieman-replay-setting h4 {
    font-size: 17px; }
  .page-qieman-replay-setting .page-header {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .page-qieman-replay-setting .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
    .page-qieman-replay-setting .header h4 {
      padding: 0;
      margin: 0; }
    .page-qieman-replay-setting .header .flex-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
      .page-qieman-replay-setting .header .flex-l h2 {
        margin-bottom: 0; }
      .page-qieman-replay-setting .header .flex-l .presort-group {
        margin-left: 20px; }
    .page-qieman-replay-setting .header .flex-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      .page-qieman-replay-setting .header .flex-r .search-input {
        margin-right: 20px;
        width: 250px; }
  .page-qieman-replay-setting .card {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: white;
    border-radius: 5px;
    align-items: flex-start;
    margin: 20px 0; }
    .page-qieman-replay-setting .card_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .page-qieman-replay-setting .card_header .search-input {
        max-width: 300px;
        margin-left: 30px; }
    .page-qieman-replay-setting .card .articles .article {
      display: flex;
      border-bottom: 1px solid #eeeeee; }
      .page-qieman-replay-setting .card .articles .article .item {
        margin: 10px 0; }
      .page-qieman-replay-setting .card .articles .article .flex-r {
        display: flex;
        flex-direction: column;
        flex: 1; }
        .page-qieman-replay-setting .card .articles .article .flex-r .textarea {
          min-height: 70px; }
      .page-qieman-replay-setting .card .articles .article .flex-a {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 20px; }
    .page-qieman-replay-setting .card .item {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 20px 0;
      width: 100%; }
      .page-qieman-replay-setting .card .item .item-title {
        margin-bottom: 10px; }
      .page-qieman-replay-setting .card .item .textarea {
        min-height: 300px; }
      .page-qieman-replay-setting .card .item .app-icon-uploader .app-icon-add {
        height: 120px;
        width: 160px;
        line-height: 100px;
        text-align: center;
        border-radius: 4px;
        color: #3340ff;
        border: 1px dashed #3340ff;
        margin-right: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center; }
      .page-qieman-replay-setting .card .item .app-icon-uploader img {
        height: 120px;
        width: 160px; }
    .page-qieman-replay-setting .card .footer {
      display: flex;
      justify-content: flex-end; }
  .page-qieman-replay-setting .keyword-card {
    align-items: stretch; }
    .page-qieman-replay-setting .keyword-card .item {
      border-bottom: 1px solid #dddddd;
      padding-bottom: 20px; }
      .page-qieman-replay-setting .keyword-card .item-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .page-qieman-replay-setting .keyword-card .item-body .header {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start; }
          .page-qieman-replay-setting .keyword-card .item-body .header .item-title {
            padding: 0;
            margin: 0;
            font-weight: 700; }
          .page-qieman-replay-setting .keyword-card .item-body .header .tag {
            margin-left: 15px; }
        .page-qieman-replay-setting .keyword-card .item-body .media {
          text-decoration: underline; }
      .page-qieman-replay-setting .keyword-card .item .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end; }
        .page-qieman-replay-setting .keyword-card .item .actions button {
          margin: 0 10px; }
    .page-qieman-replay-setting .keyword-card .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .page-qieman-replay-setting .keyword-card .footer .pagination {
        align-self: flex-end; }
