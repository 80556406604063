.page-role-admin {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .page-role-admin .list-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .page-role-admin .list-header h2 {
      margin-bottom: 0; }
  .page-role-admin .list-item {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    height: 50px;
    background: white; }

.modal-edit-role .input {
  height: 40px;
  margin-top: 10px; }

.modal-edit-role .footer {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .modal-edit-role .footer .submit-btn {
    height: 40px;
    margin-top: 30px;
    width: 200px;
    align-self: center; }

.modal-edit-role .menu-group {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .modal-edit-role .menu-group .group-check {
    display: flex; }
    .modal-edit-role .menu-group .group-check .check-g {
      color: #333333;
      font-size: 16px; }
  .modal-edit-role .menu-group .child {
    padding: 15px 0 15px 30px; }
