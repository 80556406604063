.page-shot-category {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .page-shot-category .list-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .page-shot-category .list-header h2 {
      margin-bottom: 0; }
  .page-shot-category .list-item {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    height: 50px;
    background: white; }

.modal-edit-shot-category .input {
  height: 40px;
  margin-top: 10px; }

.modal-edit-shot-category .footer {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .modal-edit-shot-category .footer .submit-btn {
    height: 40px;
    margin-top: 30px;
    width: 200px;
    align-self: center; }
