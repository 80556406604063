.page-bulletin-board {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .page-bulletin-board .header-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end; }

.modal-edit-bulletin .textArea {
  min-height: 150px; }

.modal-edit-bulletin .item {
  margin-bottom: 20px; }
  .modal-edit-bulletin .item .title {
    margin-bottom: 15px; }

.modal-edit-bulletin .footer {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
