.comp-shot-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  background: white; }
  .comp-shot-list-item .shot-preview {
    height: 120px;
    cursor: pointer; }
  .comp-shot-list-item .flex-m {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    margin-left: 13px;
    margin-right: 20px;
    height: 120px; }
    .comp-shot-list-item .flex-m .flex-m-t {
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .comp-shot-list-item .flex-m .flex-m-t .title {
        font-size: 16px;
        color: #333333;
        font-weight: 600;
        margin-bottom: 4px; }
    .comp-shot-list-item .flex-m .price {
      font-size: 16px;
      color: #ff7400;
      font-weight: 500; }
    .comp-shot-list-item .flex-m .flex-m-b {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .comp-shot-list-item .flex-m .flex-m-b .users {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex: 1; }
        .comp-shot-list-item .flex-m .flex-m-b .users .user {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          margin-right: 15px; }
          .comp-shot-list-item .flex-m .flex-m-b .users .user .avatar {
            width: 30px;
            height: 30px;
            cursor: pointer; }
          .comp-shot-list-item .flex-m .flex-m-b .users .user label {
            font-size: 15px;
            color: #333333;
            font-weight: 600;
            margin-left: 5px;
            cursor: pointer; }
      .comp-shot-list-item .flex-m .flex-m-b .flex-m-b-r {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end; }
        .comp-shot-list-item .flex-m .flex-m-b .flex-m-b-r .statistic {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center; }
          .comp-shot-list-item .flex-m .flex-m-b .flex-m-b-r .statistic .icon {
            font-size: 14px;
            color: #6a6f7b;
            margin-right: 5px; }
        .comp-shot-list-item .flex-m .flex-m-b .flex-m-b-r .lesson-number {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-left: 20px; }
  .comp-shot-list-item .flex-r {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px; }
    .comp-shot-list-item .flex-r .status {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
  .comp-shot-list-item .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .comp-shot-list-item .actions a {
      padding: 5px; }
