.index {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative; }
  .index .index-container {
    display: flex;
    flex-direction: row;
    height: calc(100% - 56px); }
    .index .index-container .index-body {
      display: flex;
      flex-direction: column;
      flex: auto;
      align-items: stretch;
      height: 100%;
      overflow-y: scroll;
      background: white; }
      .index .index-container .index-body .page-header .ant-page-header-heading-title {
        font-size: 20px; }
