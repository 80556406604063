.page-banner-board {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-banner-board .page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .page-banner-board .page-header .flex-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      .page-banner-board .page-header .flex-r .create-group-btn {
        margin-left: 20px; }
  .page-banner-board .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .page-banner-board .header .flex-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
      .page-banner-board .header .flex-l h2 {
        margin-bottom: 0; }
      .page-banner-board .header .flex-l .presort-group {
        margin-left: 20px; }
    .page-banner-board .header .flex-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      .page-banner-board .header .flex-r .search-input {
        margin-right: 20px;
        width: 250px; }
  .page-banner-board .tab-pane-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80px;
    justify-content: flex-end; }
