.page-course-create {
  padding: 25px 25px 75px 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-course-create .no-found {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
    align-items: center; }
    .page-course-create .no-found p {
      font-size: 20px; }
    .page-course-create .no-found button {
      margin-top: 30px; }
  .page-course-create .page-header {
    display: flex;
    align-items: center; }
    .page-course-create .page-header .back-icon {
      font-size: 24px;
      margin-right: 10px; }
    .page-course-create .page-header h1 {
      margin-bottom: 0; }
  .page-course-create .flex-l {
    display: flex;
    flex-direction: column; }
  .page-course-create h3 {
    color: #8F9BB3; }
  .page-course-create .input {
    height: 50px;
    font-size: 18px;
    color: #404040; }
  .page-course-create .textarea {
    font-size: 14px;
    padding: 10px;
    min-height: 120px; }
  .page-course-create .form-item {
    display: flex;
    flex-direction: column;
    padding: 10px; }
    .page-course-create .form-item .child-item {
      display: flex;
      flex-direction: row;
      padding: 10px;
      align-items: center; }
      .page-course-create .form-item .child-item .child-item-title {
        width: 150px;
        text-align: end;
        margin-right: 20px; }
    .page-course-create .form-item .time-range {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px; }
      .page-course-create .form-item .time-range .symbol {
        margin: 0 10px; }
    .page-course-create .form-item .uploader {
      width: 320px; }
      .page-course-create .form-item .uploader .ant-upload-btn {
        padding-bottom: 0; }
      .page-course-create .form-item .uploader .uploader-container {
        width: 100%;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative; }
        .page-course-create .form-item .uploader .uploader-container .progress {
          position: absolute; }
        .page-course-create .form-item .uploader .uploader-container .tip {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%; }
        .page-course-create .form-item .uploader .uploader-container .course-preview {
          width: 100%;
          min-height: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .page-course-create .form-item .uploader .uploader-container .course-preview img {
            width: 100%;
            height: 100%; }
    .page-course-create .form-item .category-selector {
      height: 50px;
      font-size: 18px; }
      .page-course-create .form-item .category-selector input {
        height: 100%; }
    .page-course-create .form-item .price-item {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .page-course-create .form-item .price-item .price-input {
        font-size: 20px;
        height: 50px;
        font-weight: 600;
        color: #ff5113;
        width: 300px;
        margin-left: 10px; }
        .page-course-create .form-item .price-item .price-input .ant-input-number-input-wrap {
          height: 100%; }
        .page-course-create .form-item .price-item .price-input input {
          height: 100%; }
      .page-course-create .form-item .price-item .ant-input-number-disabled {
        color: #cccccc; }
    .page-course-create .form-item .time-range-picker {
      margin-bottom: 15px; }
  .page-course-create .editor {
    background: white;
    padding: 15px;
    height: 800px; }
  .page-course-create .lesson-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: white;
    border-radius: 4px;
    width: 100%;
    padding: 15px; }
    .page-course-create .lesson-list .lesson-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      border-bottom: 1px solid #eeeeee; }
      .page-course-create .lesson-list .lesson-item .title {
        font-size: 15px;
        color: #333333;
        flex: 1; }
      .page-course-create .lesson-list .lesson-item .flex-r {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; }
        .page-course-create .lesson-list .lesson-item .flex-r .item-props {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start; }
          .page-course-create .lesson-list .lesson-item .flex-r .item-props .duration {
            font-size: 14px;
            color: #8F9BB3;
            margin-right: 15px; }
          .page-course-create .lesson-list .lesson-item .flex-r .item-props .status {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100px; }
        .page-course-create .lesson-list .lesson-item .flex-r .item-actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          margin-left: 20px; }
          .page-course-create .lesson-list .lesson-item .flex-r .item-actions a {
            margin: 5px; }
    .page-course-create .lesson-list .flex-b {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%; }
      .page-course-create .lesson-list .flex-b .add-lesson {
        background: none;
        border: 1px dashed #3340ff;
        margin-top: 20px;
        font-size: 15px;
        color: #3340ff;
        height: 40px;
        width: 80%;
        text-align: center;
        border-radius: 4px;
        line-height: 40px;
        cursor: pointer; }
  .page-course-create .bottom-bar {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    border-top: 1px solid #eeeeee; }
    .page-course-create .bottom-bar .publish-switch {
      margin-right: 20px; }

.course-owners {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap; }
  .course-owners .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 4px;
    padding: 15px;
    height: 100px;
    width: 150px;
    position: relative; }
    .course-owners .item .avatar {
      width: 30px; }
    .course-owners .item .nickname {
      margin-bottom: 0;
      margin-top: 4px; }
    .course-owners .item .close-icon {
      font-size: 14px;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 5px;
      right: 5px;
      color: #eeeeee;
      cursor: pointer; }
    .course-owners .item .add-btn {
      margin-top: 10px;
      font-size: 12px;
      width: 50px;
      padding: 0px;
      height: 20px; }
  .course-owners .owner-add {
    background: none;
    border: 1px dashed #3340ff;
    cursor: pointer; }

.modal-course-owners .item {
  height: 130px; }

.add-teacher-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 50px;
  height: 400px; }

.modal-user-searcher {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 50px;
  height: 500px; }
  .modal-user-searcher .searcher-container {
    margin-top: 50px; }
