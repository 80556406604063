.comp-banner-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  background: white;
  overflow: hidden; }
  .comp-banner-list-item .shot-preview {
    cursor: pointer;
    width: 100%; }
  .comp-banner-list-item .shot-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px; }
    .comp-banner-list-item .shot-text .flex-m {
      flex: 1;
      width: 100%; }
      .comp-banner-list-item .shot-text .flex-m .flex-m-t .title {
        font-size: 16px;
        color: #333333;
        font-weight: 600;
        margin-bottom: 4px; }
      .comp-banner-list-item .shot-text .flex-m .price {
        font-size: 16px;
        color: #ff7400;
        font-weight: 500; }
      .comp-banner-list-item .shot-text .flex-m .flex-m-b {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; }
        .comp-banner-list-item .shot-text .flex-m .flex-m-b .users {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex: 1; }
          .comp-banner-list-item .shot-text .flex-m .flex-m-b .users .user {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            margin-right: 15px; }
            .comp-banner-list-item .shot-text .flex-m .flex-m-b .users .user .avatar {
              width: 30px;
              height: 30px;
              cursor: pointer; }
            .comp-banner-list-item .shot-text .flex-m .flex-m-b .users .user label {
              font-size: 15px;
              color: #333333;
              font-weight: 600;
              margin-left: 5px;
              cursor: pointer; }
        .comp-banner-list-item .shot-text .flex-m .flex-m-b .flex-m-b-r {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end; }
          .comp-banner-list-item .shot-text .flex-m .flex-m-b .flex-m-b-r .statistic {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center; }
            .comp-banner-list-item .shot-text .flex-m .flex-m-b .flex-m-b-r .statistic .icon {
              font-size: 14px;
              color: #6a6f7b;
              margin-right: 5px; }
          .comp-banner-list-item .shot-text .flex-m .flex-m-b .flex-m-b-r .lesson-number {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: 20px; }
    .comp-banner-list-item .shot-text .flex-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
      .comp-banner-list-item .shot-text .flex-r .status {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center; }
  .comp-banner-list-item .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .comp-banner-list-item .actions a {
      padding: 5px; }
