.page-banner-create {
  padding: 25px 25px 75px 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fdfdfd; }
  .page-banner-create .no-found {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
    align-items: center; }
    .page-banner-create .no-found p {
      font-size: 20px; }
    .page-banner-create .no-found button {
      margin-top: 30px; }
  .page-banner-create .page-header {
    display: flex;
    align-items: center; }
    .page-banner-create .page-header .back-icon {
      font-size: 24px;
      margin-right: 10px; }
    .page-banner-create .page-header h1 {
      margin-bottom: 0; }
  .page-banner-create .flex-l {
    display: flex;
    flex-direction: column; }
  .page-banner-create h3 {
    color: #8F9BB3; }
  .page-banner-create .input {
    height: 50px;
    font-size: 18px;
    color: #404040; }
  .page-banner-create .textarea {
    font-size: 14px;
    padding: 10px;
    min-height: 120px; }
  .page-banner-create .form-item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-width: 800px; }
    .page-banner-create .form-item .uploader {
      max-width: 800px; }
      .page-banner-create .form-item .uploader .ant-upload-btn {
        padding: 0; }
      .page-banner-create .form-item .uploader .uploader-container {
        width: 100%;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative; }
        .page-banner-create .form-item .uploader .uploader-container .progress {
          position: absolute; }
        .page-banner-create .form-item .uploader .uploader-container .tip {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%; }
        .page-banner-create .form-item .uploader .uploader-container .course-preview {
          width: 100%;
          min-height: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .page-banner-create .form-item .uploader .uploader-container .course-preview img {
            width: 100%;
            height: 100%; }
    .page-banner-create .form-item .category-selector {
      height: 50px;
      font-size: 18px; }
      .page-banner-create .form-item .category-selector input {
        height: 100%; }
  .page-banner-create .bottom-bar {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    border-top: 1px solid #eeeeee; }
    .page-banner-create .bottom-bar .publish-switch {
      margin-right: 20px; }
