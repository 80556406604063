.comp-app-card-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 4px;
  position: relative;
  padding: 10px; }
  .comp-app-card-item .flex-t {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .comp-app-card-item .flex-t img {
      width: 50px; }
    .comp-app-card-item .flex-t .name {
      margin-top: 10px; }
  .comp-app-card-item .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 30px;
    width: 100%;
    padding-right: 15px; }
    .comp-app-card-item .actions .icon {
      font-size: 13px;
      margin-left: 15px;
      color: #c1c1c1;
      cursor: pointer; }
